<template>
  <div>
    <b-card class="mb-3 w-100" title="Filtro">
      <div v-if ="loadingApi" class="text-center">
        <b-spinner class="mr-2" />
      </div>
      <b-form v-else @submit.prevent="handleSubmitForm()">
        <b-row>
          <b-col cols="3">
            <b-form-group label="Data Inicial">
              <date-pick 
                v-model="$v.form.created_at_after.$model"
                :pickTime="true"
                :format="'DD-MM-YYYY HH:mm'"
                v-bind="$datePickerConfig"
                :class="$v.form.created_at_after.$error && 'is-invalid'"
                >
                  <template v-slot:default="{toggle, inputValue}">

                      <b-form-input
                        @click="toggle"
                        readonly
                        trim
                        :value="inputValue"
                      >
                      </b-form-input>
                  </template>
              </date-pick>
              <b-form-invalid-feedback v-if="!$v.form.created_at_after.isBefore">
                A data inicial, não pode ser maior que a data final
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col cols="3">
            <b-form-group label="Data Final">
              <date-pick 
                v-model="$v.form.created_at_before.$model"
                :pickTime="true"
                :format="'DD-MM-YYYY HH:mm'"
                v-bind="$datePickerConfig"
                :class="$v.form.created_at_before.$error && 'is-invalid'"
                >
                  <template v-slot:default="{toggle, inputValue}">

                      <b-form-input
                        @click="toggle"
                        readonly
                        trim
                        :value="inputValue"
                      >
                      </b-form-input>
                  </template>
              </date-pick>
            </b-form-group>
          </b-col>

          <b-col cols="3">
            <b-form-group label="Tipo de Ação">
              <multiselect
                label="name"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                placeholder="Tipo de Ação"
                v-model="form.type_action"
                :options="typeActionOptions"
              >
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Usuário">
              <multiselect
                label="full_name"
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                placeholder="Usuário"
                v-model="form.user"
                :options="userOptions"
              >
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group label="Descrição">
              <b-form-input
                v-model="form.description"
                trim
                placeholder="Descrição"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        
        
        <b-row>
          <b-col cols="12" class="text-right">
            <b-button
              type="submit"
              variant="primary"
              :disabled="isLoading"
              class="pull-right text-right"
            >
              <b-spinner v-if="isLoading" small class="mr-2" />
              <span> Buscar</span>
            </b-button>
          </b-col>
        </b-row>
        
      </b-form>
    </b-card>
    <b-card title="Resultado" class="mb-4">
      <b-table striped :items="items" :fields="fields" :busy="isBusy" small />
    </b-card>
  </div>
</template>
<script>
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from "vuelidate";
import { mapActions } from "vuex";

export default {
  mixins: [validationMixin],
  data() {
    return {
      typeActionOptions: [],
      userOptions: [],
      items: [],
      isLoading: false,
      loadingApi: true,
      isBusy: false,
      fields: [
        {
          key: "user",
          label: "Usuário",
          sortable: true,
        },
        {
          key: "type_action",
          label: "Ação",
          sortable: true,
          colspan: 5,
        },
        {
          key: "user_ip",
          label: "IP Usuário",
          sortable: true,
        },
        {
          key: "screen_function",
          label: "Tela/Funcionalidade",
          sortable: true,
        },
        {
          key: "description",
          label: "Descrição",
          sortable: true,
        },
        {
          key: 'created_at',
          label: 'Dia',
          sortable: true,
          formatter: (value, key, item) => {
            return this.moment(value).format('DD-MM-YYYY HH:mm:ss');
          }
        },
      ],
      form: {
        type_action: null,
        user: null,
        description: null,
        created_at_after: this.moment().subtract(1, 'hours').format('DD-MM-YYYY HH:mm'),
        created_at_before: this.moment().format('DD-MM-YYYY HH:mm'),
      }
    };
  },
  validations: {
    form: {
      created_at_after: {
        required,
        isAfter() {
          if (!this.form.created_at_before || !this.form.created_at_after) {
            return true;
          }
          return this.moment(this.form.created_at_after, 'DD-MM-YYYY HH:mm').isBefore(this.moment(this.form.created_at_before, 'DD-MM-YYYY HH:mm'));
        }
      },
      created_at_before: {
        required,
        isBefore() {
          if (!this.form.created_at_before || !this.form.created_at_after) {
            return true;
          }
          return this.moment(this.form.created_at_before, 'DD-MM-YYYY HH:mm').isAfter(this.moment(this.form.created_at_after, 'DD-MM-YYYY HH:mm'));
        }
      },
    }
  },

  methods: {
    ...mapActions([
      "get_all_user", "get_all_type_action", "get_logs"
    ]),
    getTypeAction() {
      return new Promise(resolve => {
        this.get_all_type_action().then(({ data }) => {
          this.typeActionOptions = data.results;
          resolve();
        });
      });
    },
    getUser() {
      return new Promise(resolve => {
        this.get_all_user().then(({ data }) => {
          this.userOptions = data.results;
          resolve();
        });
      });
    },
    handleSubmitForm(){
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        const formData = {
          ...this.form,
          created_at_before: this.moment(this.$v.form.created_at_before.$model, 'DD-MM-YYYY HH:mm').toISOString(),
          created_at_after: this.moment(this.$v.form.created_at_after.$model, 'DD-MM-YYYY HH:mm').toISOString(),
          type_action: this.form.type_action ? this.form.type_action.id : null,
          user: this.form.user ? this.form.user.id : null,
        };
        this.isBusy = true
        this.get_logs(formData).then(({ data }) => {
          this.isBusy = false
          this.items = data.results;
      });
      }  
      
    },
    async getData() {
      /**
       * Aguarda todas as chamadas,
       * para liberar a interface do usuário
       */
      await Promise.all([
        this.getTypeAction(),
        this.getUser()
      ]).then(() => {
        this.loadingApi = false;
      });
    }
  },
  created() {
    this.getData();
  }
};
</script>
